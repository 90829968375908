import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import {
  AcademyQualification,
  AcademyState,
} from '../../../../model/AcademyState';
import { ClassGroupDetail } from '../../../../model/ClassGroup';
import { HttpResponse } from '../../../../model/HttpResponse';
import { Membership, MembershipDetail } from '../../../../model/Membership';
import { AcademyAdministratorOperation } from '../../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../../model/administrator/AdministratorEntity';
import { Member } from '../../../../model/member';
import { AdministrationService } from '../../../../services/administration.service';
import { GestionService } from '../../../../services/gestion.service';
import { LoadingService } from '../../../../services/loading.service';
import { PageHeaderComponent } from '../../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../../shared/tables/crud-table/crud-table.component';
@Component({
  selector: 'app-groups-seasons-members',
  standalone: true,
  providers: [MessageService, ConfirmationService],
  imports: [
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    TableModule,
    ToolbarModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    FormsModule,
    TagModule,
  ],
  templateUrl: './groups-seasons-members.component.html',
  styleUrl: './groups-seasons-members.component.scss',
})
export class GroupsSeasonsMembersComponent implements OnInit {
  @Input()
  groupSelected!: ClassGroupDetail;

  @Output()
  closeEditDialog: EventEmitter<any> = new EventEmitter();
  memberships: Membership[] = [];
  membershipsDetail: MembershipDetail[] = [];
  header: string = '';
  showForm: boolean = false;
  showDialogForm: boolean = false;
  showMemberships: boolean = true;
  loading: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private administrationService: AdministrationService,
    private gestionService: GestionService
  ) {}

  ngOnInit(): void {
    this.getMemberships();
    this.header = ` ${this.groupSelected.course?.name} - ${this.groupSelected.level?.name} | ${this.groupSelected.sch.days} ${this.groupSelected.sch.startTime} - ${this.groupSelected.sch.endTime} | ${this.groupSelected.headquarter?.name}`;
  }

  async getMemberships() {
    this.loadingService.showLoading();
    this.loading = true;
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '=',
              evaluatedProperty: 'classGroupId',
              parameterName: '@Option1',
              parameterValue: this.groupSelected.id,
            },
            {
              conditionalConnector: 'AND',
              logicOperator: '=',
              evaluatedProperty: 'state.state',
              parameterName: '@Option2',
              parameterValue: 'Active',
            }
          ],
        },
      },
      type: AcademyAdministratorType.MEMBERSHIPS,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      if (res.response.length > 0) {
        this.memberships = res.response;
        this.membershiDetailMapper(res.response);
      } else {
        this.loading = false;
        this.showForm = true;
        this.showDialogForm = true;
        this.loadingService.hideLoading();
      }
    });
  }

  cancelDialogForm() {
    this.showDialogForm = false;
    this.closeEditDialog.emit();
  }

  async membershiDetailMapper(memberships: Membership[]) {
    this.membershipsDetail = [];
    for await (let membership of memberships) {
      let mebershipDetail: MembershipDetail = {
        ...membership,
        member: await this.getMemberById(membership.memberId),
        classGroup: this.groupSelected,
        course: this.groupSelected.course!,
        headquarter: this.groupSelected.headquarter!,
        season: this.groupSelected.season!,
        level: this.groupSelected.level,
      };

      this.membershipsDetail.push(mebershipDetail);
      this.loading = false;
      this.showForm = true;
      this.showDialogForm = true;
      this.loadingService.hideLoading();
    }
  }

  async getMemberById(id: string): Promise<Member> {
    return new Promise<Member>((resolve, reject) => {
      this.loadingService.showLoading();
      this.loading = true;
      let entity: AdministratorEntity = {
        operation: AcademyAdministratorOperation.SELECT,
        payload: {
          options: {
            conditionals: [
              {
                conditionalConnector: null,
                logicOperator: '=',
                evaluatedProperty: 'memberId',
                parameterName: '@Id',
                parameterValue: id,
              },
            ],
          },
        },
        type: AcademyAdministratorType.MEMBERS,
      };
      this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
        if (res.response.length > 0) {
          resolve(res.response[0]);
        }
      });
    });
  }

  clear(table: Table) {
    table.clear();
  }

  calculateYearsOld(birthDay: string) {
    return moment().diff(birthDay, 'years');
  }

  getSeverity(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'info';
      case AcademyQualification.APPROVED:
        return 'success';
      case AcademyQualification.FAILED:
        return 'danger';
      case AcademyQualification.PENDING:
        return 'warning';
      default:
        return 'warning';
    }
  }

  getStatus(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'En curso';
      case AcademyQualification.APPROVED:
        return 'Aprobado';
      case AcademyQualification.FAILED:
        return 'No Aprobado';
      case AcademyQualification.PENDING:
        return 'Sin Iniciar';
      default:
        return status.state;
    }
  }

  getMemberGenere(genereShort: string): string {
    switch (genereShort) {
      case 'NB':
        return 'No Binario';
      case 'M':
        return 'Masculino';
      case 'F':
        return 'Femenino';
      default:
        return 'No Especifica';
    }
  }
  exportExcel() {
    import('xlsx').then((xlsx) => {
      const dataToPrint: [] = this.getDataToPrint();
        const worksheet = xlsx.utils.json_to_sheet(dataToPrint);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, this.header);
    });

    
}

saveAsExcelFile(buffer: any, fileName: string): void {
  let EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
let EXCEL_EXTENSION = '.xlsx';
const data: Blob = new Blob([buffer], {
  type: EXCEL_TYPE,
});
FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
}

getDataToPrint():any {
 const dataToPrint = this.membershipsDetail.map((membershipDetail:MembershipDetail) => {
    const memberDetailed = {
      tipoIdentificacion:membershipDetail.member.idType,
      numeroIdentificacion:membershipDetail.member.idNumber,
      nombre:membershipDetail.member.name,
      apellido:membershipDetail.member.lastName,
      genero:this.getMemberGenere(membershipDetail.member.gender),
      edad:this.calculateYearsOld(membershipDetail.member.birthDay),
      email:membershipDetail.member.contactInformation.email,
      telefono:membershipDetail.member.contactInformation.mobile,
      estado: this.getStatus(membershipDetail.academyState)

    }
    return memberDetailed;
  });
  return dataToPrint;
}
}
