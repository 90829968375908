import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import FileSaver from 'file-saver';
import moment from 'moment';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { SelectButtonModule } from 'primeng/selectbutton';
import { Table, TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import {
  AcademyQualification,
  AcademyState,
} from '../../../model/AcademyState';
import { HttpResponse } from '../../../model/HttpResponse';
import { AcademyAdministratorOperation } from '../../../model/administrator/AcademyAdministratorOperation';
import { AcademyAdministratorType } from '../../../model/administrator/AcademyAdministratorType';
import { AdministratorEntity } from '../../../model/administrator/AdministratorEntity';
import { Member } from '../../../model/member';
import { AdministrationService } from '../../../services/administration.service';
import { GestionService } from '../../../services/gestion.service';
import { LoadingService } from '../../../services/loading.service';
import { PageHeaderComponent } from '../../shared/page-header/page-header.component';
import { CrudTableComponent } from '../../shared/tables/crud-table/crud-table.component';

@Component({
  selector: 'app-db',
  standalone: true,
  templateUrl: './db.component.html',
  styleUrl: './db.component.scss',
  providers: [MessageService, ConfirmationService],
  imports: [
    PageHeaderComponent,
    CrudTableComponent,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DialogModule,
    InputTextModule,
    CommonModule,
    InputSwitchModule,
    SelectButtonModule,
    TableModule,
    ToolbarModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    InputNumberModule,
    MultiSelectModule,
    FormsModule,
    TagModule,
  ],
})
export class DbComponent implements OnInit {
  @Output()
  closeEditDialog: EventEmitter<any> = new EventEmitter();
  members: Member[] = [];
  showForm: boolean = false;
  showDialogForm: boolean = false;
  showMemberships: boolean = true;
  loading: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private administrationService: AdministrationService,
    private gestionService: GestionService
  ) {}

  ngOnInit(): void {
    this.getMembers();
  }

  async getMembers() {
    this.loadingService.showLoading();
    this.loading = true;
    const oneYearAgo = moment().subtract(7, 'months');
    const oneYearAgoTimeStamp = moment(oneYearAgo).unix();
    let entity: AdministratorEntity = {
      operation: AcademyAdministratorOperation.SELECT,
      payload: {
        options: {
          conditionals: [
            {
              conditionalConnector: null,
              logicOperator: '>=',
              evaluatedProperty: '_ts',
              parameterName: '@TS',
              parameterValue: oneYearAgoTimeStamp,
            },
          ],
        },
      },
      type: AcademyAdministratorType.MEMBERS_MASSIVE,
    };
    this.gestionService.sendRequest(entity).subscribe((res: HttpResponse) => {
      if (res.response.length > 0) {
        this.members = res.response;
      }
      this.loading = false;
      this.showForm = true;
      this.loadingService.hideLoading();
    });
  }

  cancelDialogForm() {
    this.showDialogForm = false;
    this.closeEditDialog.emit();
  }

  clear(table: Table) {
    table.clear();
  }

  calculateYearsOld(birthDay: string) {
    return moment().diff(birthDay, 'years');
  }

  getSeverity(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'info';
      case AcademyQualification.APPROVED:
        return 'success';
      case AcademyQualification.FAILED:
        return 'danger';
      case AcademyQualification.PENDING:
        return 'warning';
      default:
        return 'warning';
    }
  }

  getStatus(status: AcademyState): string {
    switch (status.state) {
      case AcademyQualification.IN_PROGRESS:
        return 'En curso';
      case AcademyQualification.APPROVED:
        return 'Aprobado';
      case AcademyQualification.FAILED:
        return 'No Aprobado';
      case AcademyQualification.PENDING:
        return 'Sin Iniciar';
      default:
        return status.state;
    }
  }

  getMemberGenere(genereShort: string): string {
    switch (genereShort) {
      case 'NB':
        return 'No Binario';
      case 'M':
        return 'Masculino';
      case 'F':
        return 'Femenino';
      default:
        return 'No Especifica';
    }
  }
  exportExcel() {
    import('xlsx').then((xlsx) => {
      const dataToPrint = this.members.map((member: Member) => {
        const memberDetailed = {
          tipoIdentificacion: member.idType,
          numeroIdentificacion: member.idNumber,
          nombre: member.name,
          apellido: member.lastName,
          genero: this.getMemberGenere(member.gender),
          edad: this.calculateYearsOld(member.birthDay),
          email: member.contactInformation.email,
          telefono: member.contactInformation.mobile,
        };

        return memberDetailed;
      });
      const worksheet = xlsx.utils.json_to_sheet(dataToPrint);
      const workbook = {
        Sheets: { data: worksheet },
        SheetNames: ['inscritos'],
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(
        excelBuffer,
        'Base de datos General ' + '_' + moment().format('YYYY-MM-DD')
      );
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
